import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const LearnPage: React.FC = () => (
    <Layout>
        <SEO title="Learn How to Code" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Learn How to Code</h1>

                <p>
                    If you are interested in learning how to code, check out
                    some of these free resources.
                </p>
                <h2 className="title is-2">Code Self Study</h2>
                <p>
                    If you're near Berkeley, California, join{" "}
                    <a href="https://codeselfstudy.com/">Code Self Study</a>.
                </p>
                <h2 className="title is-2">
                    Automate the Boring Stuff with Python
                </h2>
                <p>
                    This{" "}
                    <a href="https://automatetheboringstuff.com/2e/">
                        free online book
                    </a>{" "}
                    can help you get started with Python. The things it teaches
                    are immediately practical.
                </p>
                <h2 className="title is-2">Harvard CS50</h2>
                <p>
                    <a href="https://www.edx.org/course/introduction-computer-science-harvardx-cs50x">
                        Harvard CS50
                    </a>{" "}
                    is a free online introduction to computer science.
                </p>
                <p>From the course description:</p>
                <blockquote>
                    <p>
                        This is CS50x, Harvard University's introduction to the
                        intellectual enterprises of computer science and the art
                        of programming for majors and non-majors alike, with or
                        without prior programming experience. An entry-level
                        course taught by David J. Malan, CS50x teaches students
                        how to think algorithmically and solve problems
                        efficiently. Topics include abstraction, algorithms,
                        data structures, encapsulation, resource management,
                        security, software engineering, and web development.
                        Languages include C, Python, SQL, and JavaScript plus
                        CSS and HTML. Problem sets inspired by real-world
                        domains of biology, cryptography, finance, forensics,
                        and gaming. As of Fall 2016, the on-campus version of
                        CS50x, CS50, was Harvard's largest course.
                    </p>
                </blockquote>
                <h2 className="title is-2">MIT's Intro to Computer Science</h2>
                <p>
                    <a href="https://www.edx.org/course/introduction-computer-science-mitx-6-00-1x-11">
                        Introduction to Computer Science and Programming Using
                        Python
                    </a>{" "}
                    is a free online course on Python.
                </p>
                <p>From the course description:</p>
                <blockquote>
                    <p>
                        This course is the first of a two-course sequence:
                        Introduction to Computer Science and Programming Using
                        Python, and Introduction to Computational Thinking and
                        Data Science. Together, they are designed to help people
                        with no prior exposure to computer science or
                        programming learn to think computationally and write
                        programs to tackle useful problems. Some of the people
                        taking the two courses will use them as a stepping stone
                        to more advanced computer science courses, but for many
                        it will be their first and last computer science
                        courses.
                    </p>
                </blockquote>
                <h2 className="title is-2">Contact Us</h2>
                <p>
                    If you have questions, please{" "}
                    <Link to="/contact/">contact us</Link>.
                </p>
            </div>
        </section>
    </Layout>
);

export default LearnPage;
